<template>
    <div class="cargoController-list">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    title: "门店管理",
                    search: [
                        {
                            key: "merchantId",
                            label: "商家名称",
                            type: 'remoteSearchById',
                            opt: {
                                list: [],
                                change(data,cb) {
                                    let findGroup = _this.opt.search.find(item => item.key == 'groupName');
                                    if(findGroup) {
                                        cb({
                                            value: "",
                                            key: findGroup.key
                                        });
                                        findGroup.opt.list = [];
                                    }
                                    _this.getStoreList(data)
                                }
                            }
                        },
                        {
                            key: "groupName",
                            label: "门店名称",
                            type: "selectCity",
                            opt: {
                                list: []
                            }
                        },
                        {
                            key: "address",
                            label: "门店位置",
                        },
                        {
                            label: "门店负责人",
                            key: "chargeName"
                        },
                        {
                            label: "负责人电话",
                            key: "chargePhone"
                        },
                    ],
                    columns: [{
                            label: "门店编码",
                            key: "groupCode",
                            // on: row => {
                            //     _this.$router.push("/main/groupManagement/detail/show/" + row.groupId)
                            // }
                        },
                        {
                            label: "门店名称",
                            key: "groupName"
                        },
                        {
                            label: "城市",
                            key: "cityStr"
                        },
                        {
                            label: "区号编码",
                            key: "areaCode"
                        },
                        {
                            label: "商家名称",
                            key: "merchantName"
                        },
                        {
                            label: "门店负责人",
                            key: "chargeName"
                        },
                        {
                            label: "负责人电话",
                            key: "chargePhone"
                        },
                        {
                            label: "门店关联货柜",
                            key: "macVal"
                        },
                        {
                            label: "详细位置",
                            key: "address"
                        },
                        {
                            label: "操作",
                            key: "userName",
                            type: "action-but",
                            opt: {
                                list: [{
                                    label: "查看",
                                    on(row) {
                                        _this.$router.push("/main/groupManagement/detail/show/" + row
                                            .groupId)

                                    }
                                }]
                            }
                        },
                    ],
                    buttons: [{
                            type: 0,
                            on() {
                                _this.$router.push("/main/groupManagement/detail/add/0")

                            }
                        },
                        {
                            type: 1,
                            on(data) {
                                if (data.data.groupId == '1') {
                                    return _this.$message({
                                        message: "默认门店不可编辑！",
                                        type: "warning"
                                    });
                                }
                                _this.$router.push("/main/groupManagement/detail/edit/" + data.data.groupId)
                            }
                        }
                    ]
                },
                cityList: [],
                merchantFlag: false,
            };
        },
        created() {
            console.log("cargoController-list created!!");
        },
        async activated() {
            try {
                // let groupList = await cabinetUtil.getStoreList();
                // this.opt.search.forEach(item => {
                //     if (item.key == 'groupName') {
                //         item.opt.list = groupList;
                //     }
                // })
                this.merchantFlag = this.getLoginType('loginType');
                if (this.merchantFlag) {
                    this.opt.search.forEach((item, index) => {
                        if (item.key == 'merchantId') {
                            this.opt.search.splice(index, 1);
                        }
                    })
                    this.opt.columns.forEach((item, index) => {
                        if (item.key == 'merchantName') {
                            this.opt.columns.splice(index, 1);
                        }
                    })
                } else {
                    this.opt.search.forEach((item, index) => {
                        if (item.key == 'merchantId') {
                            cabinetUtil.getMerchantList().then(res => {
                                item.opt.list = res;
                            })
                        }
                    })
                }
            } catch (error) {
                console.log(error)
            }
        },

        methods: {
            onGet(opt) {
                let dto = {
                    current: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm
                };
                this.getCityList().then(res => {
                    this.post("/mall-service/v1/counterStores/getList", dto).then(res => {
                        let obj = {
                            data: {
                                data: [],
                            },
                            total: 0,
                        }
                        obj.data.data = res.records;
                        obj.data.total = res.total;
                        obj.data.data.forEach(item => {
                            item.macVal = item.macList.join('、');
                            if (item.macVal.length > 42) {
                                item.macVal = item.macVal.substr(0, 42) + '...';
                            }
                            item.cityStr = this.getCityName(item.city);
                            item.merchantName = item.merchantName ? item.merchantName : '--';
                            item.chargeName = item.chargeName ? item.chargeName : '--';
                            item.chargePhone = item.chargePhone ? item.chargePhone : '--';
                        });
                        opt.cb(obj.data);
                    });
                })
                if(opt.searchForm.merchantId) {
                    this.getStoreList(opt.searchForm.merchantId);
                }else{
                    this.getStoreList();
                }
            },
            // 获取城市列表
            getCityList(name) {
                return new Promise((resolve, reject) => {
                    this.post("/landlord-service/base/getCitys").then(res => {
                        res.forEach(item => {
                            if (item.cityCode) {
                                if (item.cityCode.length == 2) {
                                    item.cityCode = '0' + item.cityCode
                                } else if (item.cityCode.length == 1) {
                                    item.cityCode = '00' + item.cityCode
                                }
                            }
                        })
                        this.cityList = res;
                        resolve(res);
                    });
                })

            },
            // 根据城市编码知道成功名字
            getCityName(val) {
                for (let i = 0; i < this.cityList.length; i++) {
                    if (val == this.cityList[i].cityCode) {
                        return this.cityList[i].name
                    }
                }
            },
            //获取门店列表
            getStoreList(data) {
                let dto = {
                    currentPage: 1,
                    size: 99999,
                };
                if(data) {
                    dto.merchantId = data
                }
                this.post("/mall-service/v1/counterStores/getAllList", dto, {
                    isUseResponse: true,
                }).then(res => {
                    let storeList = res.data.data;
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
                    this.opt.search.forEach(item => {
                        if (item.key == 'groupName') {
                        item.opt.list = storeList;
                        }
                    })
                });
            },
        }
    };
</script>
<style lang="scss">

</style>